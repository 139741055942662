import React from 'react';
import Layout from "../components/Layout";
import {defineMessages, useIntl} from "react-intl";
import juniorSenior from "../assets/images/features/junior-senior.svg";
import juniorSeniorHotfixfighters from "../assets/images/features/junior-senior-hotfixfighters.svg";
import arrow from '../assets/images/features/short-arrow.svg';
import decoLine from "../assets/images/features/decoration.svg";
import knowledgeBaseTab from "../assets/images/features/knowledge-base.svg";
import books from "../assets/images/features/books.svg";
import line from "../assets/images/features/line.svg";
import hotfixTicket from "../assets/images/features/hotfix-ticket1.svg";
import middleLine from "../assets/images/line-bottom-middle.svg";
import lineLeft from "../assets/images/line-horizontal-left.svg";
import lineRight from "../assets/images/line-horizontal-right.svg";
import line2 from "../assets/images/features/line2.svg";
import junior from "../assets/images/features/junior.svg";
import developer from "../assets/images/features/developer.svg";
import flightBoard from "../assets/images/features/flight-hours-board.svg";
import people from "../assets/images/features/people.svg";
import notes from "../assets/images/features/notes.svg";
import breakTime from "../assets/images/features/break-time.png";
import calendarSchedule from "../assets/images/features/calendar-overview.svg";
import calendarIcon from "../assets/images/features/calendar-icons.svg";
import schedulerForm from "../assets/images/features/scheduler.svg";
import owner from "../assets/images/features/owner.svg";
import circle from "../assets/images/features/circle.svg";
import recruitmentBoard from "../assets/images/features/recruitment-board.svg";
import cv from "../assets/images/features/cv.svg";
import timeline from "../assets/images/features/timeline.svg";
import juniorInterview from "../assets/images/features/junior-interview.svg";
import managerChecklist from "../assets/images/features/manager-checklist.svg";
import recruitmentForm from "../assets/images/features/recruitment-form.svg";
import interviewSchedule from "../assets/images/features/interview-schedule.svg";
import congrats from "../assets/images/features/congrats.svg";
import Img from "../components/Img";

const intro = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Other Features'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Custom features'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship offers you the features you won’t find in any competition product like: hotfix mode, flight hours, rate board etc.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Feel the difference'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Optimize performance by instilling a culture of continuous feedback and collaboration.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'junior developer showing task to the senior developer'
    }
});

const knowledgeBase = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Knowledge Base'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Easily accessible knowledge base'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship makes it easy for people to find solutions to their problems. You can create a collection of documentation that includes answers to frequently asked questions, how-to guides and troubleshooting instructions.'
    },
    protip1List1: {
        id: 'Content.Text',
        defaultMessage: 'The knowledge base is easily accessible both from Internal and Customer level'
    },
    protip1List2: {
        id: 'Content.Text',
        defaultMessage: 'It is available from the main menu for company related documents and in each project so you could separately create projects related documentation'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Manage access'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Choose who can create, edit, and see your content. \n' + 'So you can keep some information confidential only for specific group.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'knowledge base in Scrumship - project management software'
    }
})

const hotfix = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Hotfix'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Handle bugs right away'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship knows how to handle bugs that can’t wait for the next release date. Working towards a production hotfix means immediately dropping one or many work in progress items and fixing the defect for immediate validation and release.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Flash notification'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship gives you a Hotfix status. After the Customer or Product Owner creates a Hotfix request, we will immediately send a notification to all employees involved in the project. Developers can immediately start working on the reported problem.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Production Hotfixes'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'Ticket on the Scrumship board is red and goes straight  into a Bugs container.'
    },
    hotfixTicket: {
        id: 'Alt.Text',
        defaultMessage: 'hotfix ticket in Scrumship'
    }
});

const flightHours = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Flight Hours'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Instant overview'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Use this report to get an instant overview of all hours logged per individual team member.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Rate Board'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Flight Hours report is representing your employees time spent on specific projects and for specific client in total. Based on the flight hours you can organize your rate board, so you don’t have to worry about raises - let Scrumship do it for you.'
    },
    protip3Header: {
        id: 'Header.Text',
        defaultMessage: 'Create transparent working environment'
    },
    protip3Text: {
        id: 'Content.Text',
        defaultMessage: 'Breaks during working hours are vital for your team to rest up before returning to effective work. Scrumship records the time your employee has a break from work in order to create a successful and transparent working environment. '
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'flight hours board in Scrumship'
    }
});

const jumpOff = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Jump Off'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Quick notification'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Jump off is a functionality that allows you to quickly notify other employees of your temporary absence. During this time period, workers can leave their working area and ‘recharge their batteries’.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Transparent break time'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'Providing the right number of breaks to your workers is key to running a successful business.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Coffee or lunch break'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'All you have to do is click on the coffee icon to start a 30-minute break. Click again to extend your break by another 30 minutes.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'break time view in Scrumship'
    }
});

const calendar = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Calendar'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Visualize your schedule'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship calendar visualize your schedule and reminds you of important events, such as holidays and vacation time. Calendar is a useful tool for keeping track of upcoming meetings, deadlines, and milestones.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Easily schedule meetings'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'With Scrumship calendar you can quickly schedule meetings and events and get reminders about upcoming activities, so you always know what\'s next. Coloured events help you easily visualize with which project your events are related in your calendar.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Calendar Overview'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'You do not need to click on the event to view it in more detail on the calendar. Simply hover over the event to see it‘s description and clickable links.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'All Project Members'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'Rather than selecting each individual members to join the event, we can just select a whole project team. Also if we add a new member to the team it will simply update the event.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'scheduler board in Scrumship'
    },
    board2: {
        id: 'Alt.Text',
        defaultMessage: 'calendar overview in Scrumship'
    }
});

const recruitment = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Recruitment Module'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Speed up your HR processes with Scrumship.'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship helps recruiters do everything from sourcing candidates, setting up interviews, reminders and collecting updated CVs. Free your team from the repetitive manual tasks and endless email threads, so they can focus on goals and hire right candidates.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Find the best match'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Score candidates\' profiles based on job requirements to facilitate your screening process. \n' +
            'Easily remove the applications which are clearly under qualified and not suitable for the job using Reject option. Store past information for analysis and leverage them in the future. '
    },
    protip3Header: {
        id: 'Header.Text',
        defaultMessage: 'Find the right schedule'
    },
    protip3Text: {
        id: 'Content.Text',
        defaultMessage: 'Scheduling interviews efficiently saves you time and promotes positive experiences for both candidates and hiring teams.\n'
    },
    protip4Header: {
        id: 'Header.Text',
        defaultMessage: 'Ready to use forms'
    },
    protip4Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship gives you application forms that you can adjust regarding of a position you are currently hiring for. Allow for information to be present in a consistent way. '
    },
    protip5Header: {
        id: 'Header.Text',
        defaultMessage: 'Notifications'
    },
    protip5Text: {
        id: 'Content.Text',
        defaultMessage: 'The candidate would also get notified about the progress we’re making on his recruitment so nobody feels ignored regardless of the result. '
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Manage candidates'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'View candidate profiles containing resumes\' information, annotations, and feedback from interviewers.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Job interview schedule'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'Cut the back-and-forth and let your candidates schedule a time that works best with self-schedule link.'
    },
    advice3Header: {
        id: 'Header.Text',
        defaultMessage: 'Recruitment form'
    },
    advice3Text: {
        id: 'Content.Text',
        defaultMessage: 'Let your candidate answer your question, rather than spending hours on verifying custom resumes.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'recruitment board in Scrumship'
    },
    board2: {
        id: 'Alt.Text',
        defaultMessage: 'recruitment timeline in Scrumship'
    },
    board3: {
        id: 'Alt.Text',
        defaultMessage: 'interview schedule in Scrumship'
    },
    board4: {
        id: 'Alt.Text',
        defaultMessage: 'recruitment form in Scrumship'
    }
});

const globalAlts =defineMessages({
    junior: {
        id: 'Alt.Text',
        defaultMessage: 'junior developer'
    },
    developer: {
        id: 'Alt.Text',
        defaultMessage: 'senior developer'
    },
    people: {
        id: 'Alt.Text',
        defaultMessage: 'cooperating people'
    },
    notes: {
        id: 'Alt.Text',
        defaultMessage: 'flying notes'
    },
    cv: {
        id: 'Alt.Text',
        defaultMessage: 'cv'
    },
    congrats: {
        id: 'Alt.Text',
        defaultMessage: 'baner with congratulations'
    }
});

const OtherFeatures = ({location}) => {
    const {formatMessage} = useIntl();
    return (
        <Layout pageName='other-features-subpage' location={location}>
            <section className='intro'>
                <div className='container'>
                    <div className='section-one'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(intro.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(intro.protip1Header)}</h3></div>
                                <p className='protip-grey'>{formatMessage(intro.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='people'>
                            <div className='image-container full-width'>
                                <Img src={juniorSenior} alt={formatMessage(intro.board)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title title-no-line'><h3>{formatMessage(intro.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip2Text)}</p>
                            </div>
                            <div className='arrow'>
                                <Img src={arrow} alt=""/>
                            </div>
                        </div>
                        <div className='deco-line'>
                            <div className='image-container full-width'>
                                <Img src={decoLine} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='knowledge-base'>
                <div className='container'>
                    <div className='section-two'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(knowledgeBase.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(knowledgeBase.protip1Header)}</h3>
                                </div>
                                <p className='protip-black'>{formatMessage(knowledgeBase.protip1Text)}</p>
                                <ul className='list'>
                                    <li><span className='protip-black'>{formatMessage(knowledgeBase.protip1List1)}</span>
                                    </li>
                                    <li><span className='protip-black'>{formatMessage(knowledgeBase.protip1List2)}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='board'>
                            <div className='image-container full-width'>
                                <Img src={knowledgeBaseTab} alt={formatMessage(knowledgeBase.board)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(knowledgeBase.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(knowledgeBase.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='books'>
                            <div className='image-container full-width'>
                                <Img src={books} alt=""/>
                            </div>
                        </div>
                        <div className='line'>
                            <Img src={line} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='hotfix'>
                <div className='container'>
                    <div className='section-three'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(hotfix.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(hotfix.protip1Header)}</h3></div>
                                <p className='protip-grey'>{formatMessage(hotfix.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='board'>
                            <Img src={hotfixTicket} alt={formatMessage(hotfix.hotfixTicket)}/>
                        </div>
                        <div className='advice-wrapper1'>
                            <div className='advice-container advice1'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(hotfix.advice1Header)}</h4>
                                    <p>{formatMessage(hotfix.advice1Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=''/>
                                </div>
                            </div>
                            <div className='line-advice2'>
                                <div className='image-container full-width'>
                                    <Img src={line2} alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(hotfix.protip2Header)}</h3></div>
                                <p className='protip-grey'>{formatMessage(hotfix.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='junior-senior-hotfixfighters'>
                            <div className='image-container full-width'>
                                <Img src={juniorSeniorHotfixfighters} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                        <div className='junior'>
                            <div className='image-container full-width'>
                                <Img src={junior} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                        <div className='developer'>
                            <div className='image-container full-width'>
                                <Img src={developer} alt={formatMessage(globalAlts.developer)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='flight-hours'>
                <div className='container'>
                    <div className='section-fourth'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(flightHours.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(flightHours.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(flightHours.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='board'>
                            <div className='image-container full-width'>
                                <Img src={flightBoard} alt={formatMessage(flightHours.board)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(flightHours.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(flightHours.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='protip3'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(flightHours.protip3Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(flightHours.protip3Text)}</p>
                            </div>
                        </div>
                        <div className='people'>
                            <div className='image-container full-width'>
                                <Img src={people} alt={formatMessage(globalAlts.people)}/>
                            </div>
                        </div>
                        <div className='notes'>
                            <div className='image-container full-width'>
                                <Img src={notes} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='jump-off'>
                <div className='container'>
                    <div className='section-fifth'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(jumpOff.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(jumpOff.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(jumpOff.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='advice-wrapper1'>
                            <div className='advice-container advice1'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(jumpOff.advice1Header)}</h4>
                                    <p>{formatMessage(jumpOff.advice1Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice2'>
                                <div className='image-container full-width'>
                                    <Img src={line2} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='image'>
                            <div className='image-container full-width'>
                                <Img src={breakTime} alt={formatMessage(jumpOff.board)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper2'>
                            <div className='advice-container advice2'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(jumpOff.advice2Header)}</h4>
                                    <p>{formatMessage(jumpOff.advice2Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice2'>
                                <div className='image-container full-width'>
                                    <Img src={line2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='calendar'>
                <div className='container'>
                    <div className='section-sixth'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(calendar.header)}</h1>
                        </div>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(calendar.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(calendar.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='calendar'>
                            <div className='image-container full-width'>
                                <Img src={calendarSchedule} alt={formatMessage(calendar.board2)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper1'>
                            <div className='advice-container advice1'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(calendar.advice1Header)}</h4>
                                    <p>{formatMessage(calendar.advice1Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice2'>
                                <div className='image-container full-width'>
                                    <Img src={line2} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='people'>
                            <div className='image-container full-width'>
                                <Img src={circle} alt=""/>
                            </div>
                            <div className='image-container full-width'>
                                <Img src={owner} alt={formatMessage(globalAlts.developer)}/>
                            </div>
                        </div>
                        <div className='advice-wrapper2'>
                            <div className='advice-container advice2'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(calendar.advice2Header)}</h4>
                                    <p>{formatMessage(calendar.advice2Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1'>
                                <div className='image-container full-width'>
                                    <Img src={middleLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-advice2'>
                                <div className='image-container full-width'>
                                    <Img src={line2} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className='board'>
                            <div className='image-container full-width'>
                                <Img src={schedulerForm} alt={formatMessage(calendar.board)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(calendar.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(calendar.protip2Text)}</p>
                            </div>
                        </div>
                        <div className='icons'>
                            <div className='image-container full-width'>
                                <Img src={calendarIcon} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='recruitment-module'>
                <div className='container'>
                    <div className='section-seventh'>
                        <div className='row1'>
                            <div className='label'>
                                <h1 className='text-decoration-label'>{formatMessage(recruitment.header)}</h1>
                            </div>
                            <div className='protip1'>
                                <div className='content-container text-left'>
                                    <div className='title'><h3>{formatMessage(recruitment.protip1Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(recruitment.protip1Text)}</p>
                                </div>
                            </div>
                            <div className='board'>
                                <div className='image-container full-width'>
                                    <Img src={recruitmentBoard} alt={formatMessage(recruitment.board)}/>
                                </div>
                            </div>
                            <div className='advice-wrapper'>
                                <div className='advice-container advice'>
                                    <div className='advice-body'>
                                        <h4>{formatMessage(recruitment.advice1Header)}</h4>
                                        <p>{formatMessage(recruitment.advice1Text)}</p>
                                    </div>
                                </div>
                                <div className='line-advice'>
                                    <div className='image-container full-width'>
                                        <Img src={middleLine} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className='cv'>
                                <div className='image-container full-width'>
                                    <img src={cv} alt={formatMessage(globalAlts.cv)}/>
                                </div>
                            </div>
                            <div className='timeline'>
                                <div className='image-container full-width'>
                                    <Img src={timeline} alt={formatMessage(recruitment.board2)}/>
                                </div>
                            </div>
                            <div className='protip2'>
                                <div className='content-container text-left'>
                                    <div className='title'><h3>{formatMessage(recruitment.protip2Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(recruitment.protip2Text)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='row2'>
                            <div className='calendar'>
                                <div className='image-container full-width'>
                                    <Img src={juniorInterview} alt={formatMessage(globalAlts.junior)}/>
                                </div>
                            </div>
                            <div className='protip3'>
                                <div className='content-container text-left'>
                                    <div className='title'><h3>{formatMessage(recruitment.protip3Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(recruitment.protip3Text)}</p>
                                </div>
                            </div>
                            <div className='interview'>
                                <div className='image-container full-width'>
                                    <Img src={interviewSchedule} alt={formatMessage(recruitment.board3)}/>
                                </div>
                            </div>
                            <div className='advice-wrapper1'>
                                <div className='advice-container advice'>
                                    <div className='advice-body'>
                                        <h4>{formatMessage(recruitment.advice2Header)}</h4>
                                        <p>{formatMessage(recruitment.advice2Text)}</p>
                                    </div>
                                </div>
                                <div className='line-advice'>
                                    <div className='image-container full-width'>
                                        <Img src={lineLeft} alt=""/>
                                    </div>
                                </div>
                                <div className='line-advice2'>
                                    <div className='image-container full-width'>
                                        <Img src={middleLine} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className='protip4'>
                                <div className='content-container text-left'>
                                    <div className='title'><h3>{formatMessage(recruitment.protip4Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(recruitment.protip4Text)}</p>
                                </div>
                            </div>
                            <div className='form'>
                                <div className='image-container full-width'>
                                    <Img src={recruitmentForm} alt={formatMessage(recruitment.board4)}/>
                                </div>
                            </div>
                            <div className='advice-wrapper2'>
                                <div className='advice-container advice'>
                                    <div className='advice-body'>
                                        <h4>{formatMessage(recruitment.advice3Header)}</h4>
                                        <p>{formatMessage(recruitment.advice3Text)}</p>
                                    </div>
                                </div>
                                <div className='line-advice'>
                                    <div className='image-container full-width'>
                                        <img src={lineRight} alt=""/>
                                    </div>
                                </div>
                                <div className='line-advice2'>
                                    <div className='image-container full-width'>
                                        <Img src={middleLine} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className='checklist'>
                                <div className='image-container full-width'>
                                    <Img src={managerChecklist} alt={formatMessage(globalAlts.junior)}/>
                                </div>
                            </div>
                        </div>
                        <div className='row3'>
                            <div className='protip5'>
                                <div className='content-container text-left'>
                                    <div className='title'><h3>{formatMessage(recruitment.protip5Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(recruitment.protip5Text)}</p>
                                </div>
                            </div>
                            <div className='congrats'>
                                <div className='image-container full-width'>
                                    <Img src={congrats} alt={formatMessage(globalAlts.congrats)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default OtherFeatures;
